<template>
  <div class="room-allotment-change-log">
    <skeleton v-if="isLoadingProperty" width="50%" height="40px" />
    <bg-text v-else size="heading-2" class="mb-32">
      {{ xSelectedPropertyName }}
    </bg-text>

    <bg-text size="heading-4" class="mb-32">
      Riwayat Perubahan Ketersediaan Kamar
    </bg-text>

    <table class="ss-table room-allotment-change-log__table">
      <thead>
        <tr>
          <th
            v-for="(header, i) in tableHeaders"
            class="ta-l"
            :aria-label="header"
            :key="`header-${i}`"
          >
            <bg-text size="title-5">{{ header }}</bg-text>
          </th>
        </tr>
      </thead>

      <template v-if="isLoading">
        <tbody>
          <tr v-for="i in 5" :key="i">
            <td v-for="j in tableHeaders.length" :key="j">
              <skeleton width="100%" height="24px" />
            </td>
          </tr>
        </tbody>
      </template>

      <template v-else-if="changeLogData && changeLogData.length">
        <tbody data-testid="roomAllotmentChangeLog-TableBody">
          <tr v-for="(data, i) in changeLogData" :key="`data-${i}`" role="row">
            <td>
              {{ data.actor.name }}
            </td>
            <td>
              {{ data.actor.role }}
            </td>
            <td>
              {{ data.event }}
            </td>
            <td>
              {{ String(data.old_value) }}
            </td>
            <td>
              {{ String(data.new_value) }}
            </td>
            <td>
              {{ getChangedAtFormat(data.created_at) }}
            </td>
          </tr>
        </tbody>
      </template>

      <template v-else>
        <tbody class="room-allotment-change-log__no-data">
          <tr>
            <td :colspan="tableHeaders.length">
              <bg-text size="title-2" class="mb-24">
                Data Tidak Ditemukan
              </bg-text>
              <bg-text size="body-2">
                Data tidak ditemukan di filter atau kata kunci yang Anda
                gunakan.
              </bg-text>
            </td>
          </tr>
        </tbody>
      </template>
    </table>

    <template>
      <div v-if="isLoading" class="ta-c">
        <skeleton :width="175" :height="24" />
      </div>
      <bg-pagination
        v-else-if="hasPagination"
        v-model="activePage"
        :page-total="totalPaginationPage"
        @click="fetchChangeLogData"
      />
    </template>
  </div>
</template>

<script>
import { BgText, BgPagination, BgSkeleton } from 'bangul-vue';

import roomAllotmentApi from '@admin/api/endpoints/room-allotment';
import propertyDetailAPI from '@admin/api/endpoints/property-detail';

import { mapGetters, mapMutations } from 'vuex';
import { dateFormatterToDisplay } from 'Utils/formatter';

const DEFAULT_ERROR_MESSAGE = 'Terjadi kesalahan silahkan coba lagi.';

export default {
  name: 'RoomAllotmentChangeLog',

  components: {
    BgText,
    BgPagination,
    skeleton: BgSkeleton,
  },

  data() {
    return {
      isLoading: true,
      isLoadingProperty: false,
      tableHeaders: [
        'Changed by',
        'Changer Role',
        'What Changed',
        'Old Value',
        'New Value',
        'Changed at',
      ],
      changeLogData: [],
      activePage: 0,
      pagination: {
        current_page: 0,
        limit: 10,
        total_items: 0,
      },
    };
  },

  computed: {
    ...mapGetters(['xSelectedPropertyName']),

    propertyId() {
      return this.$route.params.propertyId;
    },
    hasPagination() {
      return this.totalPaginationPage > 1;
    },
    totalPaginationPage() {
      const { total_items: totalItems, limit } = this.pagination;
      return Math.ceil(totalItems / limit);
    },
  },

  created() {
    this.fetchPropertyData();
    this.fetchChangeLogData();
  },

  methods: {
    ...mapMutations('roomAllotment', ['xSetSelectedProperty']),
    ...mapMutations('breadcrumb', ['setBreadcrumb']),

    getChangedAtFormat(changedAt) {
      return dateFormatterToDisplay(changedAt, 'DD/MM/YYYY HH:mm');
    },
    setPageBreadcrumbs() {
      const breadcrumbs = [...this.$route.meta.breadcrumbs];

      const propertyBreadcrumbs = [
        {
          name: this.xSelectedPropertyName,
          url: `/property-detail/${this.propertyId}/overview`,
        },
        {
          name: 'Ketersediaan Kamar',
          url: `/room-allotment/calendar-view?property_id=${this.propertyId}`,
        },
      ];

      breadcrumbs.splice(1, 2, ...propertyBreadcrumbs);

      this.setBreadcrumb(breadcrumbs);
    },
    async fetchPropertyData() {
      if (!this.xSelectedPropertyName) {
        this.isLoadingProperty = true;

        try {
          const res = await propertyDetailAPI.getPropertyDetail(
            this.propertyId
          );

          const { data: propertyData } = res?.data || {};

          this.$store.commit('xSetSelectedProperty', {
            label: propertyData.name,
            val: propertyData.id,
          });
        } catch (error) {
          this.$toast.show(DEFAULT_ERROR_MESSAGE);
          this.$error.report(error);
        } finally {
          this.isLoadingProperty = false;
        }
      }

      this.setPageBreadcrumbs();
    },
    async fetchChangeLogData(currentPage = 1) {
      this.isLoading = true;

      const { limit } = this.pagination;
      const params = {
        offset: (currentPage - 1) * limit,
        limit,
      };

      try {
        const res = await roomAllotmentApi.getChangeLogList(
          this.propertyId,
          params
        );

        const { data: changeLogData, pagination } = res?.data || {};

        this.changeLogData = changeLogData;
        this.pagination = pagination;
      } catch (error) {
        this.$toast.show(DEFAULT_ERROR_MESSAGE);
        this.$error.report(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped src="./RoomAllotmentChangeLog.scss"></style>
